export interface TimelineEvent {
  date: string;
  title: string;
  description: string;
  image?: string;
}

export const timelineEvents: TimelineEvent[] = [
  {
    date: '2011年3月11日',
    title: '東日本大震災発生',
    description:
      '当社の本社と工場が被災し、事業活動が一時停止。壊滅的な被害を被る。',
  },
  {
    date: '2011年3月12日～21日',
    title: '対策本部設置',
    description: '橋爪会長宅に対策本部を設置。',
  },
  {
    date: '2011年3月22日',
    title: '本社機能移転',
    description:
      '本社機能を北上支店内に移設。大船渡市猪川町久名畑にも仮事務所を設置。',
  },
  {
    date: '2011年4月',
    title: '高田支店統合と給油所再開',
    description:
      '高田支店を本社に統合。大和商事(株)盛給油所が被災前の住所にて再開。',
  },
  {
    date: '2011年5月',
    title: '仮設事務所設置',
    description:
      '本店建材センターを大船渡市末崎町船河原に設置。釜石支店仮設事務所を釜石市千鳥町に設置。',
  },
  {
    date: '2011年6月',
    title: '本社機能移転と工場再開',
    description:
      '本社機能を末崎町船河原の建材センターに移設。大船渡レミコン(株)工場が被災前の住所にて再開。',
  },
  {
    date: '2011年8月',
    title: '本社機能再配置と南三陸支店設置',
    description:
      '本社機能を大船渡市大船渡町欠ノ下向に移設。本店鉄鋼・土木資材センターを大船渡市大船渡町字堀川に設置。南三陸支店を宮城県本吉郡南三陸町志津川沼田に設置。',
  },
  {
    date: '2011年9月',
    title: '金物機材センターと給油所再開',
    description:
      '本店金物機材センターが本社屋の１階で再開。大和商事(株)大船渡給油所が配送センターとして再開。',
  },
  {
    date: '2011年10月',
    title: '釜石支店再開',
    description: '釜石支店が元の場所（被災前の所在地）釜石市松原町で再開。',
  },
  {
    date: '2012年1月',
    title: 'コイン洗車場設置',
    description:
      '大和商事(株)旧大船渡給油所隣接スペースに大型洗浄可能なコイン洗車場を設置。',
  },
  {
    date: '2012年5月',
    title: '高田レミコン(株)工場移転・新設',
    description:
      '高田レミコン(株)が陸前高田市矢作町に工場を移転・新設し、生コン出荷を再開。',
  },
  {
    date: '2012年9月',
    title: '高田支店再開',
    description: '陸前高田市竹駒町に高田支店を新設し、支店としての業務を再開。',
  },
  {
    date: '2012年10月',
    title: '橋爪信栄(株)移転',
    description:
      '橋爪信栄(株)が新設された橋爪商事(株)と同敷地内の事務所に移転。',
  },
  {
    date: '2013年4月',
    title: '南三陸支店移転',
    description: '南三陸支店を志津川字御前下に新築移転。',
  },
  {
    date: '2013年11月',
    title: '釜石支店移転',
    description: '釜石支店を釜石市平田に新築移転。',
  },
  {
    date: '2017年7月',
    title: '本社・本店集約',
    description:
      '本社・本店及び建材センターを大船渡町字砂森に新築移転し、3ヶ所に分かれていた本社・本店が2ケ所に集約。',
  },
  {
    date: '2019年5月',
    title: '本社機能完全集約',
    description:
      '本店 鉄鋼･土木資材センターを本社・本店の敷地内に新築移転し、震災後分かれていた拠点が再び集約。化成品倉庫も同敷地内に移転。',
  },
];
