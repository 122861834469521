import type { Office } from "@/types/offices";

const placeholderImageUrl =
	"https://via.placeholder.com/400x300?text=営業所画像"; // Viaを使用したプレイスホルダー画像のURL

export const offices: Office[] = [
	{
		name: "本社・本店",
		address: "岩手県大船渡市大船渡町字砂森2-20",
		postalCode: "022-0002",
		phone: "0192-27-1131",
		fax: "0192-25-1176",
		mapUrl:
			"https://www.google.com/maps/d/embed?mid=1NdkIuTxMCRYC1_D6fmbOvwaqZEs&hl=ja&ehbc=2E312F",
		prefecture: "岩手県",
		imageUrl: "/images/hasizme-ofunato.png",
	},
	{
		name: "北上支店",
		address: "岩手県北上市鬼柳町笊渕66-1",
		postalCode: "024-0056",
		phone: "0197-67-3315",
		fax: "0197-67-5659",
		mapUrl:
			"https://www.google.com/maps/d/embed?mid=1C1h6Yft8uQCKQFpvgefFNUcy4EU&ehbc=2E312",
		prefecture: "岩手県",
		imageUrl: placeholderImageUrl,
	},
	{
		name: "盛岡支店",
		address: "岩手県紫波郡矢巾町流通センター南1-5-7",
		postalCode: "020-0891",
		phone: "019-637-2300",
		fax: "019-638-0105",
		mapUrl:
			"https://www.google.com/maps/d/embed?mid=1QL0Kw8ipy0FTB3Sn66rC2hHzd_c&ehbc=2E312F",
		prefecture: "岩手県",
		imageUrl: placeholderImageUrl,
	},
	{
		name: "仙台支店",
		address: "宮城県仙台市若林区鶴代町2-40",
		postalCode: "984-0001",
		phone: "022-239-7121",
		fax: "022-239-7120",
		mapUrl:
			"https://www.google.com/maps/d/embed?mid=1V9iZsJwuGzAnTxXTu33Pil8BdJE&ehbc=2E312F",
		prefecture: "宮城県",
		imageUrl: placeholderImageUrl,
	},
	{
		name: "三戸支店",
		address: "青森県三戸郡三戸町大字目時字中野31-4",
		postalCode: "039-0113",
		phone: "0179-22-2321",
		fax: "0179-22-1982",
		mapUrl:
			"https://www.google.com/maps/d/embed?mid=1-v2pf83gNaVPZxNPGkY1IH0eYAw&ehbc=2E312F",
		prefecture: "青森県",
		imageUrl: placeholderImageUrl,
	},
	{
		name: "南三陸支店",
		address: "宮城県本吉郡南三陸町志津川字御前下14-1",
		postalCode: "986-0768",
		phone: "0226-46-4766",
		fax: "0226-46-4767",
		mapUrl:
			"https://www.google.com/maps/d/embed?mid=1c0NZLTt2aSPLXQCJZkSpdf-EERo&ehbc=2E312F",
		prefecture: "宮城県",
		imageUrl: placeholderImageUrl,
	},
	{
		name: "大曲支店",
		address: "秋田県大仙市大曲西根字仁応治500-2",
		postalCode: "014-0072",
		phone: "0187-68-3000",
		fax: "0187-68-3005",
		mapUrl:
			"https://www.google.com/maps/d/embed?mid=1Ht-LdtS6Ss6rqnbZsltu3dVrW2E&ehbc=2E312F",
		prefecture: "秋田県",
		imageUrl: placeholderImageUrl,
	},
	{
		name: "宮古支店",
		address: "岩手県宮古市田鎖第9地割52-6",
		postalCode: "027-0036",
		phone: "0193-64-0881",
		fax: "0193-63-7854",
		mapUrl:
			"https://www.google.com/maps/d/embed?mid=13QMm93XKaSqYZwcwXVKyw0KIZIM&ehbc=2E312F",
		prefecture: "岩手県",
		imageUrl: placeholderImageUrl,
	},
	{
		name: "久慈支店",
		address: "岩手県久慈市川貫第6地割40-1",
		postalCode: "028-0082",
		phone: "0194-52-4361",
		fax: "0194-52-2102",
		mapUrl:
			"https://www.google.com/maps/d/embed?mid=1-LlGT6k84G4gzjIpXHC7fSTlqjo&ehbc=2E312F",
		prefecture: "岩手県",
		imageUrl: placeholderImageUrl,
	},
	{
		name: "一関支店",
		address: "岩手県一関市萩荘字高梨東5-1",
		postalCode: "021-0902",
		phone: "0191-24-2035",
		fax: "0191-24-2168",
		mapUrl:
			"https://www.google.com/maps/d/embed?mid=1qAk_Vnlz5TRo7WfQDeK5M-lwkZk&ehbc=2E312",
		prefecture: "岩手県",
		imageUrl: placeholderImageUrl,
	},
	{
		name: "高田支店",
		address: "岩手県陸前高田市竹駒町字十日市場4-1",
		postalCode: "029-2203",
		phone: "0192-55-2131",
		fax: "0192-54-3482",
		mapUrl:
			"https://www.google.com/maps/d/embed?mid=1qUj0Zkunj5L12GluU8mEvYMpleY&ehbc=2E312F",
		prefecture: "岩手県",
		imageUrl: "/images/hasizme-takata.png",
	},
	{
		name: "釜石支店",
		address: "岩手県釜石市大字平田第１地割16番2",
		postalCode: "026-0001",
		phone: "0193-55-6015",
		fax: "0193-36-1156",
		mapUrl:
			"https://www.google.com/maps/d/embed?mid=1PoteUXFgVGEKTjwMq1NhEcKuWOo&ehbc=2E312F",
		prefecture: "岩手県",
		imageUrl: placeholderImageUrl,
	},
	{
		name: "気仙沼営業所",
		address: "宮城県気仙沼市長磯森57-4",
		postalCode: "988-0227",
		phone: "0226-28-9021",
		fax: "0226-28-9022",
		mapUrl:
			"https://www.google.com/maps/d/embed?mid=1CDyEsgX6lmg-qprs1dayKUhnJSo&ehbc=2E312F",
		prefecture: "宮城県",
		imageUrl: placeholderImageUrl,
	},
	{
		name: "奥州営業所",
		address: "岩手県胆沢郡金ヶ崎町六原森合46-3",
		postalCode: "029-4501",
		phone: "0197-43-2081",
		fax: "0197-43-2082",
		mapUrl:
			"https://www.google.com/maps/d/embed?mid=1NNP2QHotZBFLGdDdg276R6ZCbv8&ehbc=2E312F",
		prefecture: "岩手県",
		imageUrl: placeholderImageUrl,
	},
	{
		name: "宮古支店 岩泉出張所",
		address: "岩手県下閉伊郡岩泉町岩泉字志田16-1",
		postalCode: "027-0501",
		phone: "0194-32-3001",
		fax: "0194-32-3002",
		mapUrl:
			"https://www.google.com/maps/d/embed?mid=1_4C2yLTrvuYKyMlPh2HtDGJFtq5pEUx2&ehbc=2E312F",
		prefecture: "岩手県",
		imageUrl: placeholderImageUrl,
	},
	{
		name: "本店 遠野出張所",
		address: "岩手県遠野市宮守町下鱒沢17地割69-4",
		postalCode: "028-0303",
		phone: "0198-66-3810",
		fax: "0198-66-3811",
		mapUrl:
			"https://www.google.com/maps/d/embed?mid=1lTX0gJDC0LWiuDBv9JPQyZmyZ8xm6rhK&ehbc=2E312F",
		prefecture: "岩手県",
		imageUrl: placeholderImageUrl,
	},
];

export const prefectures = Array.from(
	new Set(offices.map((office) => office.prefecture)),
);
