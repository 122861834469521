// 型のインポート
import type { RawProduct } from "@/types/product";

// 実際のデータ
export const products: RawProduct[] = [
	{
		image: "https://via.placeholder.com/300x200?text=セメント",
		title: "セメント",
		description: "あらゆる建設ニーズに対応する高品質なセメント。",
		category: "セメント",
		categoryColor: "#007bff",
		registrationDate: "2023-04-01",
		recommended: true,
	},
	{
		image: "https://via.placeholder.com/300x200?text=鋼材",
		title: "鋼材",
		description: "強度と信頼性に優れた耐久性のある鋼材。",
		category: "鋼材・土木",
		categoryColor: "#28a745",
		registrationDate: "2023-03-15",
		recommended: false,
	},
	{
		image: "https://via.placeholder.com/300x200?text=骨材",
		title: "骨材",
		description: "コンクリートやその他の用途に最適な高品質の骨材。",
		category: "建材",
		categoryColor: "#ffc107",
		registrationDate: "2023-03-20",
		recommended: true,
	},
	{
		image: "https://via.placeholder.com/300x200?text=レンガ",
		title: "レンガ",
		description: "耐火性と耐久性に優れた高品質なレンガ。",
		category: "建材",
		categoryColor: "#ffc107",
		registrationDate: "2023-02-28",
		recommended: false,
	},
	{
		image: "https://via.placeholder.com/300x200?text=砂",
		title: "砂",
		description: "建設やその他の用途に最適な高品質の砂。",
		category: "建材",
		categoryColor: "#ffc107",
		registrationDate: "2023-03-10",
		recommended: false,
	},
	{
		image: "https://via.placeholder.com/300x200?text=砕石",
		title: "砕石",
		description: "道路建設や基礎工事に最適な高品質の砕石。",
		category: "建材",
		categoryColor: "#ffc107",
		registrationDate: "2023-03-05",
		recommended: true,
	},
	{
		image: "https://via.placeholder.com/300x200?text=コンクリートブロック",
		title: "コンクリートブロック",
		description: "強度と耐久性に優れた高品質なコンクリートブロック。",
		category: "建材",
		categoryColor: "#ffc107",
		registrationDate: "2023-02-20",
		recommended: false,
	},
	{
		image: "https://via.placeholder.com/300x200?text=アスファルト",
		title: "アスファルト",
		description: "道路舗装に最適な高品質のアスファルト。",
		category: "建材",
		categoryColor: "#ffc107",
		registrationDate: "2023-03-25",
		recommended: true,
	},
	{
		image: "https://via.placeholder.com/300x200?text=ガラス",
		title: "ガラス",
		description: "建築用途に最適な高品質のガラス。",
		category: "建材",
		categoryColor: "#ffc107",
		registrationDate: "2023-03-30",
		recommended: false,
	},
	{
		image: "https://via.placeholder.com/300x200?text=木材",
		title: "木材",
		description: "建築や家具製作に最適な高品質の木材。",
		category: "建材",
		categoryColor: "#ffc107",
		registrationDate: "2023-04-05",
		recommended: true,
	},
	{
		image: "https://via.placeholder.com/300x200?text=高強度コンクリート",
		title: "高強度コンクリート",
		description: "超高層ビルや橋梁に最適な高強度コンクリート。",
		category: "セメント",
		categoryColor: "#007bff",
		registrationDate: "2023-04-10",
		recommended: true,
	},
	{
		image: "https://via.placeholder.com/300x200?text=鉄筋",
		title: "鉄筋",
		description: "建築・土木工事に不可欠な高品質鉄筋。",
		category: "鋼材・土木",
		categoryColor: "#28a745",
		registrationDate: "2023-04-12",
		recommended: false,
	},
	{
		image: "https://via.placeholder.com/300x200?text=断熱材",
		title: "断熱材",
		description: "エネルギー効率を高める高性能断熱材。",
		category: "建材",
		categoryColor: "#ffc107",
		registrationDate: "2023-04-15",
		recommended: true,
	},
	{
		image: "https://via.placeholder.com/300x200?text=配管材",
		title: "配管材",
		description: "耐久性に優れた様々な用途の配管材。",
		category: "金物",
		categoryColor: "#dc3545",
		registrationDate: "2023-04-18",
		recommended: false,
	},
	{
		image: "https://via.placeholder.com/300x200?text=防水剤",
		title: "防水剤",
		description: "建築物の寿命を延ばす高性能防水剤。",
		category: "化成品",
		categoryColor: "#6c757d",
		registrationDate: "2023-04-20",
		recommended: true,
	},
	{
		image: "https://via.placeholder.com/300x200?text=建設管理ソフトウェア",
		title: "建設管理ソフトウェア",
		description: "建設プロジェクトの効率を向上させる管理ソフトウェア。",
		category: "DX",
		categoryColor: "#17a2b8",
		registrationDate: "2023-04-22",
		recommended: true,
	},
	{
		image: "https://via.placeholder.com/300x200?text=型枠",
		title: "型枠",
		description: "コンクリート打設に使用する高品質な型枠。",
		category: "建材",
		categoryColor: "#ffc107",
		registrationDate: "2023-04-25",
		recommended: false,
	},
	{
		image: "https://via.placeholder.com/300x200?text=H形鋼",
		title: "H形鋼",
		description: "建築構造用の高強度H形鋼。",
		category: "鋼材・土木",
		categoryColor: "#28a745",
		registrationDate: "2023-04-28",
		recommended: true,
	},
];
export default products;
