import type { CSRActivity } from "@/types/csr";

export const csrActivities: CSRActivity[] = [
	{
		id: "local-cleaning",
		title: "地域清掃活動",
		description: "毎月第一土曜日に、地域の公園や道路の清掃活動を行っています。",
		fullDescription:
			"私たちの地域清掃活動は、地域社会との強い結びつきを築くための重要な取り組みです。毎月第一土曜日に、従業員やその家族、地域のボランティアが集まり、地元の公園や道路、河川敷などの清掃を行っています。この活動を通じて、環境美化への意識を高めるとともに、地域コミュニティの絆を深めています。",
		thumbnail: "https://via.placeholder.com/300x150.png?text=地域清掃活動",
		image: "https://via.placeholder.com/800x400.png?text=地域清掃活動",
		date: "毎月第一土曜日",
		participants: "従業員、家族、地域ボランティア",
		impact: "年間約1000kgのゴミを収集・処理",
	},
	{
		id: "environmental-protection",
		title: "環境保護プロジェクト",
		description:
			"地域の森林保護と植樹活動に参加し、生態系の維持に貢献しています。",
		fullDescription:
			"私たちの環境保護プロジェクトは、地域の生態系を守り、持続可能な未来を築くことを目的としています。地元の森林保護団体と協力し、定期的な植樹活動や森林整備を行っています。また、希少種の保護活動にも参加し、生物多様性の維持に貢献しています。",
		thumbnail:
			"https://via.placeholder.com/300x150.png?text=環境保護プロジェクト",
		image: "https://via.placeholder.com/800x400.png?text=環境保護プロジェクト",
		date: "年2回（春と秋）",
		participants: "従業員、環境保護団体",
		impact: "年間1000本の植樹、5ヘクタールの森林整備",
	},
	{
		id: "education-support",
		title: "教育支援プログラム",
		description:
			"地域の学校に建設資材を寄付し、実習施設の整備を支援しています。",
		fullDescription:
			"私たちの教育支援プログラムは、次世代を担う若者たちの学びを支援することを目的としています。地域の学校と連携し、建設や工学に関する実習施設の整備を行っています。また、当社の専門家が学校を訪問し、建設業界のキャリアについての講演を行うなど、実践的な教育支援も実施しています。",
		thumbnail:
			"https://via.placeholder.com/300x150.png?text=教育支援プログラム",
		image: "https://via.placeholder.com/800x400.png?text=教育支援プログラム",
		date: "年間を通じて実施",
		participants: "従業員、地域の学校",
		impact: "年間10校の支援、500名以上の学生にリーチ",
	},
	{
		id: "disaster-recovery",
		title: "災害復興支援",
		description:
			"自然災害被災地への建設資材の提供や、復興作業のボランティア活動を行っています。",
		fullDescription:
			"自然災害の被災地に対して、迅速かつ効果的な支援を行うことは私たちの社会的責任の一つです。建設資材の提供や、従業員によるボランティア活動を通じて、被災地の復興を支援しています。また、災害に強いインフラ整備のための技術提供も行っています。",
		thumbnail: "https://via.placeholder.com/300x150.png?text=災害復興支援",
		image: "https://via.placeholder.com/800x400.png?text=災害復興支援",
		date: "災害発生時および復興期間中",
		participants: "従業員、協力会社",
		impact: "年間3件の災害支援、延べ1000人日のボランティア活動",
	},
	{
		id: "elderly-support",
		title: "高齢者支援活動",
		description:
			"地域の高齢者施設を訪問し、レクリエーション活動のサポートを行っています。",
		fullDescription:
			"高齢化が進む地域社会において、高齢者の方々の生活の質を向上させることは重要な課題です。私たちは定期的に地域の高齢者施設を訪問し、レクリエーション活動のサポートや、施設の小規模な修繕作業を行っています。この活動を通じて、高齢者の方々との交流を深め、地域社会との絆を強めています。",
		thumbnail: "https://via.placeholder.com/300x150.png?text=高齢者支援活動",
		image: "https://via.placeholder.com/800x400.png?text=高齢者支援活動",
		date: "月1回",
		participants: "従業員、ボランティア",
		impact: "年間12回の訪問活動、延べ500人の高齢者とふれあい",
	},
	{
		id: "recycling-promotion",
		title: "リサイクル推進活動",
		description:
			"建設現場での資材リサイクルを推進し、環境負荷の低減に取り組んでいます。",
		fullDescription:
			"建設業界における環境負荷の低減は、持続可能な社会の実現に向けて重要な課題です。私たちは建設現場での資材リサイクルを積極的に推進し、廃棄物の削減に取り組んでいます。また、リサイクル技術の開発や、環境に配慮した建設手法の採用にも力を入れています。",
		thumbnail:
			"https://via.placeholder.com/300x150.png?text=リサイクル推進活動",
		image: "https://via.placeholder.com/800x400.png?text=リサイクル推進活動",
		date: "通年",
		participants: "全従業員、協力会社",
		impact: "年間リサイクル率80%達成、CO2排出量15%削減",
	},
	{
		id: "sports-promotion",
		title: "地域スポーツ振興",
		description:
			"地域のスポーツイベントへの協賛や、子供向けスポーツ教室の開催を支援しています。",
		fullDescription:
			"地域の健康増進と子供たちの健全な成長を支援するため、様々なスポーツ振興活動を行っています。地域のスポーツイベントへの協賛や、当社の施設を利用した子供向けスポーツ教室の開催など、スポーツを通じた地域貢献に力を入れています。また、地元のスポーツチームの支援も行っています。",
		thumbnail: "https://via.placeholder.com/300x150.png?text=地域スポーツ振興",
		image: "https://via.placeholder.com/800x400.png?text=地域スポーツ振興",
		date: "年間を通じて実施",
		participants: "従業員、地域住民、地元スポーツチーム",
		impact: "年間10件のイベント協賛、1000名以上の子供たちが参加",
	},
];
